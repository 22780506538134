import React from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "../styles/Contact.css"
import InputPN from "../components/InputPN"
import fbIcon from "../images/fbIcon.svg"
import twitterIcon from "../images/twitterIcon.svg"
import googleIcon from "../images/googleIcon.svg"
import circlepng from "../images/circlepng.png"
import { Helmet } from "react-helmet"
import { useState } from "react"

export default function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [errorName, setErrorName] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const [successMessage, setSuccessMessage] = useState(false)

  const handleName = data => {
    setName(data)
  }

  const handleEmail = data => {
    setEmail(data)
  }

  const handleMessage = data => {
    setMessage(data)
  }

  // Example POST method implementation:
  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  const postReq = e => {
    e.preventDefault()
    if (!name) {
      setErrorName(true)
    } else {
      setErrorName(false)
    }

    if (!email) {
      setErrorEmail(true)
    } else {
      setErrorEmail(false)
    }

    if (!message) {
      setErrorMessage(true)
    } else {
      setErrorMessage(false)
    }

    if (name && email && message) {
      postData("https://content.reaim.me/site-contacts", {
        name: name,
        email: email,
        message: message,
      })
        .then(() => {
          setSuccessMessage(true)
        })
        .catch(error => console.log(error))
    }
  }

  return (
    <div>
      <Helmet>
        <title>
          Contact | ReAim - Reach your audience with push notifications
        </title>
      </Helmet>
      <Navigation />
      <div>
        <div className="contact-container">
          <div className="pricing-header">
            <h1>How can we help?</h1>
            <h3>
              We are more than happy to answer all your questions. <br /> Just
              fill up this short form and we will be in touch in no time.
            </h3>
          </div>
          <div className="box-info-container">
            <form className="contact-box">
              <h5 className="contact-h5 contact-h5-first">Name</h5>

              <InputPN
                placeholder="Enter your name"
                styles={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Manrope",
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "26px",
                  color: "rgb(2, 14, 23)",
                  borderBottom: "1px solid rgba(2, 14, 23, 10%)",
                }}
                handler={data => {
                  handleName(data)
                }}
              />
              <div
                className={
                  errorName ? "contact-error-show" : "contact-name-error"
                }
              >
                <p className="contact-name-error-p">
                  * This field is required.
                </p>
              </div>

              <h5 className="contact-h5">Email</h5>

              <InputPN
                placeholder="Enter your email"
                styles={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Manrope",
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "26px",
                  color: "rgb(2, 14, 23)",
                  borderBottom: "1px solid rgba(2, 14, 23, 5%)",
                }}
                handler={data => {
                  handleEmail(data)
                }}
              />

              <div
                className={
                  errorEmail ? "contact-error-show" : "contact-name-error"
                }
              >
                <p className="contact-name-error-p">
                  * This field is required.
                </p>
              </div>

              <h5 className="contact-h5">Message</h5>

              <InputPN
                placeholder="Tell us about it"
                styles={{
                  backgroundColor: "#ffffff",
                  fontFamily: "Manrope",
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "26px",
                  color: "rgb(2, 14, 23)",
                  borderBottom: "1px solid rgba(2, 14, 23, 5%)",
                }}
                handler={data => {
                  handleMessage(data)
                }}
              />
              <div
                className={
                  errorMessage ? "contact-error-show" : "contact-name-error"
                }
              >
                <p className="contact-name-error-p">
                  * This field is required.
                </p>
              </div>

              <div className="contact-hr"></div>
              <div className="contact-buttons">
                <div className="contact-start-button-container">
                  <button
                    className="contact-start-button"
                    alt="Start"
                    onClick={postReq}
                  >
                    <span className="contact-start-text">Send</span>
                    <span className="contact-start-arrow"></span>
                  </button>
                </div>
              </div>
              <div
                className={
                  successMessage ? "contact-error-show" : "contact-name-error"
                }
              >
                <p className="success-message-p">
                  * Sent Successfully. We will get in touch with you soon.
                </p>
              </div>
            </form>
            <div className="contact-info">
              <h5 className="contact-h5 contact-h5-first contact-h5-info">
                EMAIL
              </h5>
              <h3 className="contact-info-h3">hello@reaim.me</h3>

              <h5 className="contact-h5 contact-h5-info">PHONE</h5>
              <h3 className="contact-info-h3">+44-7723-545082</h3>

              <h5 className="contact-h5 contact-h5-info">ADDRESS</h5>
              <h3 className="contact-info-h3">
                71-75 Shelton Street <br /> London, WC2H 9JQ
              </h3>

              <h5 className="contact-h5 contact-h5-info">SOCIAL</h5>
              <div className="contact-icons-container">
                <span className="contact-icon-span contact-icon-span1">
                  <img
                    src={fbIcon}
                    className="contact-icon"
                    alt="facebook Icon"
                  />{" "}
                </span>
                <span className="contact-icon-span contact-icon-span2">
                  <img
                    src={googleIcon}
                    className="contact-icon"
                    alt="google icon"
                  />
                </span>
                <span className="contact-icon-span contact-icon-span3">
                  <img
                    src={twitterIcon}
                    className="contact-icon"
                    alt="twitter icon"
                  />
                </span>
              </div>
            </div>
            <div className="contact-circle">
              <img
                src={circlepng}
                className="contact-circle-img"
                alt="circle"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
