import React from "react"
import "../styles/InputPN.css"

export default ({ handler = function() {}, placeholder, styles = {} }) => {
  const handleChange = e => {
    handler(e.target.innerText.trim())
  }

  return (
    <div
      className="resize-input"
      contentEditable="true"
      spellCheck="false"
      onInput={handleChange}
      placeholder={placeholder}
      style={styles}
    />
  )
}
